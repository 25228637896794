
import { Component, Prop, Vue } from "vue-property-decorator";
import LoanRule from "@/models/Liink/LoanRule";
import Lender from "@/models/Liink/Lender";
import Catalog from "@/models/Liink/Catalog";

@Component
export default class LoanRuleDrawer extends Vue {
  @Prop() lenders!: Array<Lender>;
  @Prop() employmentStatus!: Array<Catalog>;
  @Prop() employmentTimeWorked!: Array<Catalog>;
  @Prop() rule!: LoanRule;
  @Prop() create!: boolean;
  @Prop() open!: boolean;
  @Prop() loading!: boolean;

  rules = {
    lenderId: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    amountFrom: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      { type: "number", message: "Debe ser un valor numérico." },
    ],
    amountTo: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      { type: "number", message: "Debe ser un valor numérico." },
    ],
    ageFrom: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      { type: "number", message: "Debe ser un valor numérico." },
    ],
    ageTo: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      { type: "number", message: "Debe ser un valor numérico." },
    ],
    employmentStatusId: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    timeWorkedId: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    monthlyIncome: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      { type: "number", message: "Debe ser un valor numérico." },
    ],
    creditScore: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      { type: "number", message: "Debe ser un valor numérico." },
    ],
  };

  createRule() {
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (valid) {
        this.$emit("create", this.rule);
      }
    });
  }

  updateRule() {
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (valid) {
        this.$emit("update", this.rule.id, this.rule);
      }
    });
  }

  closeDrawer() {
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("close");
  }
}
